import { mapActions, mapMutations } from 'vuex';
import { Component } from 'vue-property-decorator';
import { VueWizard } from '@/vue-wizard';
import Axios from 'axios';
import { BASE_URL_MANAGER } from '@/config';

@Component({
  name: 'register-form',
  methods: {
    ...mapMutations(['setUser']),
    ...mapActions(['login']),
  },
})
export default class RegisterForm extends VueWizard {
  setUser!: any;
  public registerForm = {
    country: undefined,
    name: '',
    lastname: '',
    shopname: '',
    phone: '',
    email: '',
    password: '',
    password_confirmation: '',
  };
  public rules = {
    country: [
      {
        required: true,
        message: 'Por favor seleccione un pais',
        trigger: 'change',
      },
    ],
    name: [
      {
        required: true,
        min: 2,
        max: 50,
        pattern: /^[a-zA-Z ]{2,50}$/,
        trigger: 'blur',
        validator: (rule: any, value: any, callback: any) => {
          // must be at least 2 characters, with message 1/50 , 2/50 while typing
          if (value === '') {
            callback(new Error('Ingrese un nombre'));
          } else if (value.length < 2) {
            callback(new Error('Minimo 2 caracteres'));
          } else if (value.length > 50) {
            callback(new Error('Maximo 50 caracteres'));
          } else {
            callback();
          }
        },
      },
    ],
    lastname: [
      {
        required: true,
        min: 2,
        max: 50,
        pattern: /^[a-zA-Z ]{2,50}$/,
        trigger: 'blur',
        validator: (rule: any, value: any, callback: any) => {
          // must be at least 2 characters, with message 1/50 , 2/50 while typing
          if (value === '') {
            callback(new Error('Ingrese un nombre'));
          } else if (value.length < 2) {
            callback(new Error('Minimo 2 caracteres'));
          } else if (value.length > 50) {
            callback(new Error('Maximo 50 caracteres'));
          } else {
            callback();
          }
        },
      },
    ],
    shopname: [
      {
        required: true,
        pattern: /^[a-zA-Z ]{2,50}$/,
        // message: 'Tamaño minimo 2 y maximo 50 caracteres',
        trigger: 'blur',
        // wait 2 seconds after typing
        validator: async (rule: any, value: any, callback: any) => {
          // validate if shopname is available
          let isAvailable = this.statesValidations[0];
          if (value !== '') {
            isAvailable = await this.checkShopname(value);
          }
          if (value === '') {
            callback(new Error('Ingrese un nombre de tienda'));
          }

          if (isAvailable === this.statesValidations[1]) {
            // callback with Nombre de tienda disponible
            callback();
          } else {
            console.log('Nombre de tienda no disponible');

            callback(new Error('Nombre de tienda no disponible'));
          }
        },
      },
    ],
    phone: [
      {
        required: true,
        message: 'Use solo numeros',
        pattern: /^\d*$/,
        trigger: 'change',
      },
    ],
    email: [
      {
        required: true,
        message: 'Ingrese un correo valido',
        pattern: /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/,
        trigger: 'change',
      },
      {
        validator: async (rule: any, value: any, callback: any) => {
          // validate if shopname is available
          let isAvailable = this.stateShopname[0];
          if (value !== '') {
            isAvailable = await this.checkEmail(value);
          }
          if (value === '') {
            callback(new Error('Ingrese un email'));
          }

          if (isAvailable === this.stateShopname[1]) {
            // callback with Nombre de tienda disponible
            callback();
          } else {
            callback(new Error('Email no disponible'));
          }
        },
      },
    ],
    password: [
      {
        required: true,
        trigger: 'change',
        // at least 8 characters, 1 uppercase, 1 lowercase and 1 number and no spaces allow ñ symbol
        pattern: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[ A-Za-z0-9_@./#&+-ñÑ]{8,}$/,
        validator: (rule: any, value: any, callback: any) => {
          if (value === '') {
            callback(new Error('Ingrese una contraseña'));
          } else if (value.length < 8) {
            callback(new Error('Minimo 8 caracteres'));
          } else if (value.length > 50) {
            callback(new Error('Maximo 50 caracteres'));
          } else {
            callback();
          }
          // validate password_confirmation
          const form = this.$refs.registerForm as any;
          form.validateField('password_confirmation');
        },
      },
    ],
    // confirm_pasword must be the same as password
    // validate while typing
    password_confirmation: [
      {
        required: true,
        // message: 'Please input your password again',
        pattern: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[ A-Za-z0-9_@./#&+-ñÑ]{8,}$/,
        trigger: 'change',
        validator: (rule: any, value: any, callback: any) => {
          if (value !== this.registerForm.password) {
            callback(new Error('Las contraseñas no coinciden'));
          } else if (value === '') {
            callback(new Error('Ingrese una contraseña'));
          } else if (value.length < 8) {
            callback(new Error('Minimo 8 caracteres'));
          } else if (value.length > 50) {
            callback(new Error('Maximo 50 caracteres'));
          } else {
            callback();
          }
        },
      },
    ],
  };
  public statesValidations = ['validating', 'success', 'error', 'warning'];
  public stateShopname = ['validating', 'success', 'error', 'warning'];
  public state = '';
  public stateEmail = '';
  public textValidating = 'Validando nombre de tienda ...';
  public textSuccess = 'Nombre de tienda disponible';
  public textSuccesEmail = 'Email disponible';
  public textError = 'Nombre de tienda no disponible';
  public currentText = '';
  public currenteTextEmail = '';
  public enableSubmit = false;
  public indicative = '+57';
  // useForm
  validateForm(prop: string, valid: boolean) {
    // validate the form
    const form = this.$refs.registerForm as any;
    form
      .validate()
      .then(async () => {
        await this.handleValidSubmit();
      })
      .catch(() => {
        console.log('invalid');
      });

    // return valid;
  }
  resetForm() {
    this.registerForm = {
      country: undefined,
      name: '',
      lastname: '',
      shopname: '',
      phone: '',
      email: '',
      password: '',
      password_confirmation: '',
    };
    // reset form
    const form = this.$refs.registerForm as any;
    form.resetFields();
  }
  async handleValidSubmit() {
    const registerUrl = `${BASE_URL_MANAGER}/auth/register`;
    try {
      const dataUser = {
        company_name: this.registerForm.shopname,
        first_name: this.registerForm.name,
        last_name: this.registerForm.lastname,
        email: this.registerForm.email,
        password: this.registerForm.password,
        confirm_password: this.registerForm.password_confirmation,
        country: this.registerForm.country,
        phone: `${this.indicative}${this.registerForm.phone}`,
      };

      const response = await Axios.post(registerUrl, dataUser);
      if (response.status === 200) {
        this.$notification.success({
          placement: 'bottomRight',
          message: 'Success',
          description: 'Registro realizado con éxito',
        });
        const userId = response.data.company.users[0]._id;
        window.dataLayer.push({
          event: 'register',
          uniqueId: userId,
          name: this.registerForm.name,
          email: this.registerForm.email,
          country: this.registerForm.country,
          shopName: this.registerForm.shopname,
          phone: `${this.indicative}${this.registerForm.phone}`,
        });
        this.resetForm();
        this.$router.push('/login');
      } else {
        this.$notification.warning({
          placement: 'bottomRight',
          message: 'Error',
          description: 'Error al registrar',
        });
      }
    } catch (error) {
      this.$notification.warning({
        placement: 'bottomRight',
        message: 'Error',
        description: 'Error al registrar',
      });
    }
  }

  // watch shopname to validate if it is available
  // wait 2 seconds after typing
  async checkShopname(shopname: string) {
    const shopnameUrl = `${BASE_URL_MANAGER}/auth/${shopname}`;
    this.state = this.statesValidations[0];
    try {
      this.currentText = this.textValidating;
      const response = await Axios.get(shopnameUrl);

      if (response.status === 404) {
        this.state = this.statesValidations[1];
        this.currentText = this.textSuccess;
      } else if (response.status === 200) {
        this.state = this.statesValidations[2];
        this.currentText = this.textError;
      }
    } catch (error) {
      this.state = this.statesValidations[1];
      this.currentText = this.textSuccess;
    }
    return this.state;
  }

  async checkEmail(email: string) {
    const emailUrl = `${BASE_URL_MANAGER}/auth/username/${email}`;
    this.stateEmail = this.stateShopname[0];
    try {
      this.currenteTextEmail = this.textValidating;
      const response = await Axios.get(emailUrl);

      if (response.status === 404) {
        this.stateEmail = this.stateShopname[1];
        this.currenteTextEmail = this.textSuccesEmail;
      } else if (response.status === 200) {
        this.stateEmail = this.stateShopname[2];
        this.currenteTextEmail = this.textError;
      }
    } catch (error) {
      this.stateEmail = this.stateShopname[1];
      this.currenteTextEmail = this.textSuccesEmail;
    }
    return this.stateEmail;
  }

  handleCheckbox(event: any) {
    this.enableSubmit = event.target.checked;
  }

  handleChangeIndicator(indicative: string) {
    this.indicative = indicative;
  }
}
