import { Component } from 'vue-property-decorator';
import { VueWizard } from '@/vue-wizard';
// Components
import RegisterForm from '@/app/components/register-form/RegisterForm.vue';
import moment from 'moment';

@Component({
  name: 'app-register',
  components: { RegisterForm },
})
export default class AppRegister extends VueWizard {
  get currentYear(): string {
    return moment().format('YYYY');
  }
}
